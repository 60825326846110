import { render, staticRenderFns } from "./features.vue?vue&type=template&id=8c91e414&scoped=true&"
import script from "./features.vue?vue&type=script&lang=js&"
export * from "./features.vue?vue&type=script&lang=js&"
import style0 from "./features.vue?vue&type=style&index=0&id=8c91e414&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c91e414",
  null
  
)

export default component.exports