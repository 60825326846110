import { render, staticRenderFns } from "./createGroupModalPages.vue?vue&type=template&id=66ae6cc8&scoped=true&"
import script from "./createGroupModalPages.vue?vue&type=script&lang=js&"
export * from "./createGroupModalPages.vue?vue&type=script&lang=js&"
import style0 from "./createGroupModalPages.vue?vue&type=style&index=0&id=66ae6cc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ae6cc8",
  null
  
)

export default component.exports